import { values } from "lodash";

export const TABS = {
  HOME: 1,
  MY_COURSES: 2,
  MY_GROUPS: 3,
  EXPLORE: 4,
  JOIN_GROUP: 5,
  CREATE_GROUP: 6,
  PROFILE_EDIT: 7,
  GROUP_EDIT: 8,
  ADD_PROFILE: 9,
  GROUP_DESC: 10,
  PASSWORD_EDIT: 11,
  EMAIL_CHANGE: 12,
  COURSE: 13,
  PROFILE_NAME_EDIT: 14,
  NOTIFICATIONS_ABC: 15,
  MANAGE_DEVICES: 16,
  TROUBLESHOOT_NOTIFICATIONS: 17,
  COURSE_PAGE: 18,
  GRADEBOOK: 19,
  CERTIFICATE: 20,
  CERTIFICATE_VIEW: 21,
  BASIC_DETAILS: 22,
  EDUCATION_DETAILS: 23,
  PROFESSIONAL_DETAILS: 24,
  RESUME: 25,
  PROFILE_PREVIEW: 26,
  ATTENDANCE: 27,
  CALENDAR: 28,
  NSDC_DETAILS: 29,
};

export const PASSING_YEAR = [
  { value: "" },
  { value: "2005" },
  { value: "2006" },
  { value: "2007" },
  { value: "2008" },
  { value: "2009" },
  { value: "2010" },
  { value: "2011" },
  { value: "2012" },
  { value: "2013" },
  { value: "2014" },
  { value: "2015" },
  { value: "2016" },
  { value: "2017" },
  { value: "2018" },
  { value: "2019" },
  { value: "2020" },
  { value: "2021" },
  { value: "2022" },
  { value: "2023" },
  { value: "2024" },
  { value: "2025" },
  { value: "2026" },
  { value: "2027" },
  { value: "2028" },
  { value: "2029" },
  { value: "2030" },
];

export const BOOL_DROPDOWN = [
  { label: "", value: "0" },
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const EMPLOYMENT_TYPE = [
  { label: "", value: "0" },
  { label: "Full Time", value: "Full Time" },
  { label: "Internship", value: "Internship" },
];

export const GENDER_DROPDOWN = [
  { label: "", value: "0" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];

export const BOARD_DROPDOWN = [
  { label: "", value: "0" },
  { label: "CBSE", value: "CBSE" },
  { label: "ICSE", value: "ICSE" },
  { label: "IB", value: "IB" },
  { label: "State Board", value: "State Board" },
];

export const BACHELORS_DROPDOWN = [
  { label: "", value: "0" },
  {
    label: "Bachelor of Commerce (BCOM)",
    value: "Bachelor of Commerce (BCOM)",
  },
  {
    label: "Bachelor of Business Management (BBM)",
    value: "Bachelor of Business Management (BBM)",
  },
  { label: "Bachelor of Arts (BA)", value: "Bachelor of Arts (BA)" },
  { label: "Bachelor of Science (BSC)", value: "Bachelor of Science (BSC)" },
  {
    label: "Bachelor of Economics (BEconomics)",
    value: "Bachelor of Economics (BEconomics)",
  },
  {
    label: "Bachelor of Business Administration (BBA)",
    value: "Bachelor of Business Administration (BBA)",
  },
  {
    label: "Bachelor of Technology (B.Tech)",
    value: "Bachelor of Technology (B.Tech)",
  },
  {
    label: "Bachelor of Engineering (BE)",
    value: "Bachelor of Engineering (BE)",
  },
  {
    label: "Bachelor of Computer Applications (BCA)",
    value: "Bachelor of Computer Applications (BCA)",
  },
  { label: "Others", value: "Others" },
];

export const MASTERS_DROPDOWN = [
  { label: "", value: "" },
  { label: "Masters of Commerce (MCOM)", value: "Masters of Commerce (MCOM)" },
  {
    label: "Masters of Bussiness Management (MBM)",
    value: "Masters of Bussiness Management (MBM)",
  },
  { label: "Masters of Arts (MA)", value: "Masters of Arts (MA)" },
  { label: "Masters of Science (MSC)", value: "Masters of Science (MSC)" },
  {
    label: "Masters of Economics (MEconomics)",
    value: "Masters of Economics (MEconomics)",
  },
  {
    label: "Masters of Business Administration (MBA)",
    value: "Masters of Business Administration (MBA)",
  },
  {
    label: "Masters of Technology (M.Tech)",
    value: "Masters of Technology (M.Tech)",
  },
  {
    label: "Masters of Engineering (ME)",
    value: "Masters of Engineering (ME)",
  },
  {
    label: "Masters of Computer Applications (MCA)",
    value: "Masters of Computer Applications (MCA)",
  },
  { label: "Others", value: "Others" },
];

export const JOB_LOCATIONS = [
  { value: "Mumbai", label: "Mumbai" },
  { value: "Thane", label: "Thane" },
  { value: "Pune", label: "Pune" },
  { value: "Jaipur", label: "Jaipur" },
  { value: "Delhi-NCR", label: "Delhi-NCR" },
  { value: "Gurgaon", label: "Gurgaon" },
  { value: "Noida", label: "Noida" },
  { value: "Lucknow", label: "Lucknow" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Dehradun", label: "Dehradun" },
  { value: "Patna", label: "Patna" },
  { value: "Bangalore", label: "Bangalore" },
  { value: "Chennai", label: "Chennai" },
  { value: "Hyderabad", label: "Hyderabad" },
  { value: "Coimbatore", label: "Coimbatore" },
  { value: "Cochin", label: "Cochin" },
  { value: "Kolkata", label: "Kolkata" },
  { value: "Ahmedabad", label: "Ahmedabad" },
  { value: "Others", label: "Others" },
];

export const OVERALL_JOB_LOCATIONS = [
  { value: "0", label: "" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Thane", label: "Thane" },
  { value: "Pune", label: "Pune" },
  { value: "Jaipur", label: "Jaipur" },
  { value: "Delhi-NCR", label: "Delhi-NCR" },
  { value: "Gurgaon", label: "Gurgaon" },
  { value: "Noida", label: "Noida" },
  { value: "Lucknow", label: "Lucknow" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Dehradun", label: "Dehradun" },
  { value: "Patna", label: "Patna" },
  { value: "Bangalore", label: "Bangalore" },
  { value: "Chennai", label: "Chennai" },
  { value: "Hyderabad", label: "Hyderabad" },
  { value: "Coimbatore", label: "Coimbatore" },
  { value: "Cochin", label: "Cochin" },
  { value: "Kolkata", label: "Kolkata" },
  { value: "Ahmedabad", label: "Ahmedabad" },
  { value: "Others", label: "Others" },
];

export const CCODS = [
  { code: "IN", value: "91", display: "India (+91)" },
  { code: "US", value: "1", display: "USA (+1)" },
  { code: "GB", value: "44", display: "UK (+44)" },
  { code: "DZ", value: "213", display: "Algeria (+213)" },
  { code: "AD", value: "376", display: "Andorra (+376)" },
  { code: "AO", value: "244", display: "Angola (+244)" },
  { code: "AI", value: "1264", display: "Anguilla (+1264)" },
  { code: "AG", value: "1268", display: "Antigua &amp; Barbuda (+1268)" },
  { code: "AR", value: "54", display: "Argentina (+54)" },
  { code: "AM", value: "374", display: "Armenia (+374)" },
  { code: "AW", value: "297", display: "Aruba (+297)" },
  { code: "AU", value: "61", display: "Australia (+61)" },
  { code: "AT", value: "43", display: "Austria (+43)" },
  { code: "AZ", value: "994", display: "Azerbaijan (+994)" },
  { code: "BS", value: "1242", display: "Bahamas (+1242)" },
  { code: "BH", value: "973", display: "Bahrain (+973)" },
  { code: "BD", value: "880", display: "Bangladesh (+880)" },
  { code: "BB", value: "1246", display: "Barbados (+1246)" },
  { code: "BY", value: "375", display: "Belarus (+375)" },
  { code: "BE", value: "32", display: "Belgium (+32)" },
  { code: "BZ", value: "501", display: "Belize (+501)" },
  { code: "BJ", value: "229", display: "Benin (+229)" },
  { code: "BM", value: "1441", display: "Bermuda (+1441)" },
  { code: "BT", value: "975", display: "Bhutan (+975)" },
  { code: "BO", value: "591", display: "Bolivia (+591)" },
  { code: "BA", value: "387", display: "Bosnia Herzegovina (+387)" },
  { code: "BW", value: "267", display: "Botswana (+267)" },
  { code: "BR", value: "55", display: "Brazil (+55)" },
  { code: "BN", value: "673", display: "Brunei (+673)" },
  { code: "BG", value: "359", display: "Bulgaria (+359)" },
  { code: "BF", value: "226", display: "Burkina Faso (+226)" },
  { code: "BI", value: "257", display: "Burundi (+257)" },
  { code: "KH", value: "855", display: "Cambodia (+855)" },
  { code: "CM", value: "237", display: "Cameroon (+237)" },
  { code: "CA", value: "1", display: "Canada (+1)" },
  { code: "CV", value: "238", display: "Cape Verde Islands (+238)" },
  { code: "KY", value: "1345", display: "Cayman Islands (+1345)" },
  { code: "CF", value: "236", display: "Central African Republic (+236)" },
  { code: "CL", value: "56", display: "Chile (+56)" },
  { code: "CN", value: "86", display: "China (+86)" },
  { code: "CO", value: "57", display: "Colombia (+57)" },
  { code: "KM", value: "269", display: "Comoros (+269)" },
  { code: "CG", value: "242", display: "Congo (+242)" },
  { code: "CK", value: "682", display: "Cook Islands (+682)" },
  { code: "CR", value: "506", display: "Costa Rica (+506)" },
  { code: "HR", value: "385", display: "Croatia (+385)" },
  { code: "CU", value: "53", display: "Cuba (+53)" },
  { code: "CY", value: "90392", display: "Cyprus North (+90392)" },
  { code: "CY", value: "357", display: "Cyprus South (+357)" },
  { code: "CZ", value: "42", display: "Czech Republic (+42)" },
  { code: "DK", value: "45", display: "Denmark (+45)" },
  { code: "DJ", value: "253", display: "Djibouti (+253)" },
  { code: "DM", value: "1809", display: "Dominica (+1809)" },
  { code: "DO", value: "1809", display: "Dominican Republic (+1809)" },
  { code: "EC", value: "593", display: "Ecuador (+593)" },
  { code: "EG", value: "20", display: "Egypt (+20)" },
  { code: "SV", value: "503", display: "El Salvador (+503)" },
  { code: "GQ", value: "240", display: "Equatorial Guinea (+240)" },
  { code: "ER", value: "291", display: "Eritrea (+291)" },
  { code: "EE", value: "372", display: "Estonia (+372)" },
  { code: "ET", value: "251", display: "Ethiopia (+251)" },
  { code: "FK", value: "500", display: "Falkland Islands (+500)" },
  { code: "FO", value: "298", display: "Faroe Islands (+298)" },
  { code: "FJ", value: "679", display: "Fiji (+679)" },
  { code: "FI", value: "358", display: "Finland (+358)" },
  { code: "FR", value: "33", display: "France (+33)" },
  { code: "GF", value: "594", display: "French Guiana (+594)" },
  { code: "PF", value: "689", display: "French Polynesia (+689)" },
  { code: "GA", value: "241", display: "Gabon (+241)" },
  { code: "GM", value: "220", display: "Gambia (+220)" },
  { code: "GE", value: "7880", display: "Georgia (+7880)" },
  { code: "DE", value: "49", display: "Germany (+49)" },
  { code: "GH", value: "233", display: "Ghana (+233)" },
  { code: "GI", value: "350", display: "Gibraltar (+350)" },
  { code: "GR", value: "30", display: "Greece (+30)" },
  { code: "GL", value: "299", display: "Greenland (+299)" },
  { code: "GD", value: "1473", display: "Grenada (+1473)" },
  { code: "GP", value: "590", display: "Guadeloupe (+590)" },
  { code: "GU", value: "671", display: "Guam (+671)" },
  { code: "GT", value: "502", display: "Guatemala (+502)" },
  { code: "GN", value: "224", display: "Guinea (+224)" },
  { code: "GW", value: "245", display: "Guinea - Bissau (+245)" },
  { code: "GY", value: "592", display: "Guyana (+592)" },
  { code: "HT", value: "509", display: "Haiti (+509)" },
  { code: "HN", value: "504", display: "Honduras (+504)" },
  { code: "HK", value: "852", display: "Hong Kong (+852)" },
  { code: "HU", value: "36", display: "Hungary (+36)" },
  { code: "IS", value: "354", display: "Iceland (+354)" },
  { code: "ID", value: "62", display: "Indonesia (+62)" },
  { code: "IR", value: "98", display: "Iran (+98)" },
  { code: "IQ", value: "964", display: "Iraq (+964)" },
  { code: "IE", value: "353", display: "Ireland (+353)" },
  { code: "IL", value: "972", display: "Israel (+972)" },
  { code: "IT", value: "39", display: "Italy (+39)" },
  { code: "JM", value: "1876", display: "Jamaica (+1876)" },
  { code: "JP", value: "81", display: "Japan (+81)" },
  { code: "JO", value: "962", display: "Jordan (+962)" },
  { code: "KZ", value: "7", display: "Kazakhstan (+7)" },
  { code: "KE", value: "254", display: "Kenya (+254)" },
  { code: "KI", value: "686", display: "Kiribati (+686)" },
  { code: "KP", value: "850", display: "Korea North (+850)" },
  { code: "KR", value: "82", display: "Korea South (+82)" },
  { code: "KW", value: "965", display: "Kuwait (+965)" },
  { code: "KG", value: "996", display: "Kyrgyzstan (+996)" },
  { code: "LA", value: "856", display: "Laos (+856)" },
  { code: "LV", value: "371", display: "Latvia (+371)" },
  { code: "LB", value: "961", display: "Lebanon (+961)" },
  { code: "LS", value: "266", display: "Lesotho (+266)" },
  { code: "LR", value: "231", display: "Liberia (+231)" },
  { code: "LY", value: "218", display: "Libya (+218)" },
  { code: "LI", value: "417", display: "Liechtenstein (+417)" },
  { code: "LT", value: "370", display: "Lithuania (+370)" },
  { code: "LU", value: "352", display: "Luxembourg (+352)" },
  { code: "MO", value: "853", display: "Macao (+853)" },
  { code: "MK", value: "389", display: "Macedonia (+389)" },
  { code: "MG", value: "261", display: "Madagascar (+261)" },
  { code: "MW", value: "265", display: "Malawi (+265)" },
  { code: "MY", value: "60", display: "Malaysia (+60)" },
  { code: "MV", value: "960", display: "Maldives (+960)" },
  { code: "ML", value: "223", display: "Mali (+223)" },
  { code: "MT", value: "356", display: "Malta (+356)" },
  { code: "MH", value: "692", display: "Marshall Islands (+692)" },
  { code: "MQ", value: "596", display: "Martinique (+596)" },
  { code: "MR", value: "222", display: "Mauritania (+222)" },
  { code: "YT", value: "269", display: "Mayotte (+269)" },
  { code: "MX", value: "52", display: "Mexico (+52)" },
  { code: "FM", value: "691", display: "Micronesia (+691)" },
  { code: "MD", value: "373", display: "Moldova (+373)" },
  { code: "MC", value: "377", display: "Monaco (+377)" },
  { code: "MN", value: "976", display: "Mongolia (+976)" },
  { code: "MS", value: "1664", display: "Montserrat (+1664)" },
  { code: "MA", value: "212", display: "Morocco (+212)" },
  { code: "MZ", value: "258", display: "Mozambique (+258)" },
  { code: "MN", value: "95", display: "Myanmar (+95)" },
  { code: "NA", value: "264", display: "Namibia (+264)" },
  { code: "NR", value: "674", display: "Nauru (+674)" },
  { code: "NP", value: "977", display: "Nepal (+977)" },
  { code: "NL", value: "31", display: "Netherlands (+31)" },
  { code: "NC", value: "687", display: "New Caledonia (+687)" },
  { code: "NZ", value: "64", display: "New Zealand (+64)" },
  { code: "NI", value: "505", display: "Nicaragua (+505)" },
  { code: "NE", value: "227", display: "Niger (+227)" },
  { code: "NG", value: "234", display: "Nigeria (+234)" },
  { code: "NU", value: "683", display: "Niue (+683)" },
  { code: "NF", value: "672", display: "Norfolk Islands (+672)" },
  { code: "NP", value: "670", display: "Northern Marianas (+670)" },
  { code: "NO", value: "47", display: "Norway (+47)" },
  { code: "OM", value: "968", display: "Oman (+968)" },
  { code: "PW", value: "680", display: "Palau (+680)" },
  { code: "PA", value: "507", display: "Panama (+507)" },
  { code: "PG", value: "675", display: "Papua New Guinea (+675)" },
  { code: "PY", value: "595", display: "Paraguay (+595)" },
  { code: "PE", value: "51", display: "Peru (+51)" },
  { code: "PH", value: "63", display: "Philippines (+63)" },
  { code: "PL", value: "48", display: "Poland (+48)" },
  { code: "PT", value: "351", display: "Portugal (+351)" },
  { code: "PR", value: "1787", display: "Puerto Rico (+1787)" },
  { code: "QA", value: "974", display: "Qatar (+974)" },
  { code: "RE", value: "262", display: "Reunion (+262)" },
  { code: "RO", value: "40", display: "Romania (+40)" },
  { code: "RU", value: "7", display: "Russia (+7)" },
  { code: "RW", value: "250", display: "Rwanda (+250)" },
  { code: "SM", value: "378", display: "San Marino (+378)" },
  { code: "ST", value: "239", display: "Sao Tome &amp; Principe (+239)" },
  { code: "SA", value: "966", display: "Saudi Arabia (+966)" },
  { code: "SN", value: "221", display: "Senegal (+221)" },
  { code: "CS", value: "381", display: "Serbia (+381)" },
  { code: "SC", value: "248", display: "Seychelles (+248)" },
  { code: "SL", value: "232", display: "Sierra Leone (+232)" },
  { code: "SG", value: "65", display: "Singapore (+65)" },
  { code: "SK", value: "421", display: "Slovak Republic (+421)" },
  { code: "SI", value: "386", display: "Slovenia (+386)" },
  { code: "SB", value: "677", display: "Solomon Islands (+677)" },
  { code: "SO", value: "252", display: "Somalia (+252)" },
  { code: "ZA", value: "27", display: "South Africa (+27)" },
  { code: "ES", value: "34", display: "Spain (+34)" },
  { code: "LK", value: "94", display: "Sri Lanka (+94)" },
  { code: "SH", value: "290", display: "St. Helena (+290)" },
  { code: "KN", value: "1869", display: "St. Kitts (+1869)" },
  { code: "SC", value: "1758", display: "St. Lucia (+1758)" },
  { code: "SD", value: "249", display: "Sudan (+249)" },
  { code: "SR", value: "597", display: "Suriname (+597)" },
  { code: "SZ", value: "268", display: "Swaziland (+268)" },
  { code: "SE", value: "46", display: "Sweden (+46)" },
  { code: "CH", value: "41", display: "Switzerland (+41)" },
  { code: "SI", value: "963", display: "Syria (+963)" },
  { code: "TW", value: "886", display: "Taiwan (+886)" },
  { code: "TJ", value: "7", display: "Tajikstan (+7)" },
  { code: "TH", value: "66", display: "Thailand (+66)" },
  { code: "TG", value: "228", display: "Togo (+228)" },
  { code: "TO", value: "676", display: "Tonga (+676)" },
  { code: "TT", value: "1868", display: "Trinidad &amp; Tobago (+1868)" },
  { code: "TN", value: "216", display: "Tunisia (+216)" },
  { code: "TR", value: "90", display: "Turkey (+90)" },
  { code: "TM", value: "7", display: "Turkmenistan (+7)" },
  { code: "TM", value: "993", display: "Turkmenistan (+993)" },
  {
    code: "TC",
    value: "1649",
    display: "Turks &amp; Caicos Islands (+1649)",
  },
  { code: "TV", value: "688", display: "Tuvalu (+688)" },
  { code: "UG", value: "256", display: "Uganda (+256)" },
  { code: "UA", value: "380", display: "Ukraine (+380)" },
  { code: "AE", value: "971", display: "United Arab Emirates (+971)" },
  { code: "UY", value: "598", display: "Uruguay (+598)" },
  { code: "UZ", value: "7", display: "Uzbekistan (+7)" },
  { code: "VU", value: "678", display: "Vanuatu (+678)" },
  { code: "VA", value: "379", display: "Vatican City (+379)" },
  { code: "VE", value: "58", display: "Venezuela (+58)" },
  { code: "VN", value: "84", display: "Vietnam (+84)" },
  { code: "VG", value: "84", display: "Virgin Islands - British (+1284)" },
  { code: "VI", value: "84", display: "Virgin Islands - US (+1340)" },
  { code: "WF", value: "681", display: "Wallis &amp; Futuna (+681)" },
  { code: "YE", value: "969", display: "Yemen (North)(+969)" },
  { code: "YE", value: "967", display: "Yemen (South)(+967)" },
  { code: "ZM", value: "260", display: "Zambia (+260)" },
  { code: "ZW", value: "263", display: "Zimbabwe (+263)" },
];

export const ROLES = {
  TEACHER: 1,
  PARENT: 2,
  STUDENT: 3,
};
export const OTP_EMAIL = {
  LENGTH: 6,
  RESEND_TIMEOUT: 60,
};
export const OTP_MOBILE = {
  LENGTH: 6,
  RESEND_TIMEOUT: 60,
};
export const RESOLUTION_MODES = {
  WEB: 1,
  TABLET: 2,
  MOBILE: 3,
};

export const AWS_BUCKET_URLS = {
  PPIC: "https://eckppic.s3.amazonaws.com",
  GPIC: "https://eckgpic.s3.amazonaws.com",
};

export const ADOBE_PDF_READER_KEY = {};

export const EXPLORE_SECTIONS = {
  TRENDING_COURSES: 1,
  FREE_COURSES: 2,
  FEATURED_GROUPS: 3,
  TRENDING_GROUPS: 4,
  RUNNING_COURSES: 5,
  FINANCE_COURSES: 6,
  TECHNOLOGY_COURSES: 7,
  ANALYTICS_COURSES: 8,
  MARKETING_COURSES: 9,
};

export const SIDEBARS = {
  HOME_SIDEBAR: 1,
  GROUP_SIDEBAR: 2,
};

export const PLUGIN_CATEGORY = {
  VIDEO: 1,
  QUIZ: 2,
  FEE: 3,
  FEED: 4,
  ANALYSIS: 5,
  COURSE: 6,
  PAID_GROUP: 7,
  ADAPTIVE_COURSE: 8,
};

export const GROUP_MEMBER_TYPE = {
  ADMIN: 1,
  MEMBER: 2,
  BANNED: 3,
};

export const PLUGIN_ROUTE_VALUES = {
  ADMIN: 5999,
  NOT_ADMIN: 8999,
};

export const GROUP_TYPES = {
  ONE_WAY: false,
  TWO_WAY: true,
};
export const PLUGIN_MODAL_VIEWS = {
  PACKAGE: 1,
  PAYMENT: 2,
};
export const PAYMENT_MODES = {
  NET_BANKING: "NB",
  DEBIT_CARD: "DC",
  CREDIT_CARD: "CC",
  WALLET: "OW",
};

export const POPOVER_HEADINGS = {
  INVALID_PP_EXT: "Invalid Extension",
  NO_IMAGE_SELECTION: "No Image Selection",
  SPECIAL_CHARACTER: "Invalid Entry!",
  NAME_START_WITH_NUM: "Invalid Entry!",
  INVALID_NAME: "Invalid Name!",
  INVALID_NUMBER: "Invalid Number!",
  INVALID_EMAIL: "Invalid Email!",
  PASSWORD_ERROR: "Invalid Password!",
  P_NAME_LENGTH_EXCEEDED: "Length Exceeded!",
  CH_NAME_LENGTH_EXCEEDED: "Length Exceeded!",
  PPIC_SIZE_MAX: "Size Exceeded!",
  PIC_SIZE_MIN: "Size Too Small",
  PPIC_UPLOAD_ERR: "File Upload Failure",
  GPIC_UPLOAD_ERR: "File Upload Failure",
  PPIC_UPLOAD_SUCCESS: "Profile Details Updated!",
  GPIC_UPLOAD_SUCCESS: "Group Details Updated!",
  GRP_NAME_LENGTH_EXCEEDED: "Length Exceeded!",
  GRP_LEAVE_FAILURE: "Error!",
  GRP_LEAVE_SUCCESS: "Success",
  DEL_PPIC_SUCCESS: "Success",
  DEL_GPIC_SUCCESS: "Success",
  DEL_PPIC_FAILURE: "Error!",
  PROFILE_GROUP_EXISTS: "Error!",
  DEL_GPIC_FAILURE: "Error!",
  ONLY_ONE_PROFILE: "Error!",
  ERROR: "Error!",
  SUCCESS: "Success!",
  DEL_PROFILE_SUCCESS: "Success!",
  DEL_GROUP_SUCCESS: "Success!",
};

export const POPOVER_CONTENT = {
  INVALID_PP_EXT: "File must be JPG, JPEG or PNG.",
  NO_IMAGE_SELECTION:
    "Please drag on the image and select the image to display.",
  SPECIAL_CHARACTER:
    "Name cannot contain any special characters except underscore.",
  NAME_START_WITH_NUM: "Name cannot start with number.",
  INVALID_NAME: "This name is not valid.",
  INVALID_EMAIL: "This email is not valid.",
  P_NAME_LENGTH_EXCEEDED:
    "Profile name must be less than or equal to 50 characters.",
  CH_NAME_LENGTH_EXCEEDED:
    "Child name must be less than or equal to 25 characters.",
  PIC_SIZE_MAX: "The File you uploaded is too large to upload.",
  PIC_SIZE_MIN: "The File you uploaded is too small to upload.",
  PPIC_UPLOAD_ERR: "File can't be uploaded due to some network issue.",
  GPIC_UPLOAD_ERR: "File can't be uploaded due to some network issue.",
  PPIC_UPLOAD_SUCCESS: "Your profile details have being updated successfully.",
  GPIC_UPLOAD_SUCCESS: "Group details have being updated successfully.",
  GRP_NAME_LENGTH_EXCEEDED:
    "Group name must be less than or equal to 25 characters.",
  GRP_LEAVE_FAILURE: "Unable to leave Group, Please try again.",
  GRP_LEAVE_SUCCESS: "Your attempt to leave the group was successful.",
  DEL_PPIC_SUCCESS: "Your profile photo have being removed successfully.",
  DEL_PPIC_FAILURE: "Unable to delete profile photo, Please try again.",
  DEL_GPIC_SUCCESS: "Group photo have being removed successfully.",
  DEL_GPIC_FAILURE: "Unable to delete group photo, Please try again.",
  PROFILE_GROUP_EXISTS:
    "Please leave all the groups before deleting this profile.",
  DEL_PROFILE_FAILURE: "Unable to delete profile, Please try again.",
  ONLY_ONE_PROFILE: "You cannot delete the only profile you have!",
  DEL_PROFILE_SUCCESS: "Your profile has been deleted successfully!",
  DEL_GROUP_SUCCESS: "Group has been deleted successfully",
  GROUP_NOT_FOUND: "The Group does not exist!",
  NOT_A_GROUP_MEMBER: "You are not a member of the group!",
  AUTHENTICATION_ERROR: "You are not authenticated to perform this action!",
  GROUP_MEMBERS_ALIVE: "Members still exist in the group!",
  DEL_GROUP_FAILURE: "Unable to delete group, Please try again.",
  PROFILE_ALREADY_EXISTS: "Profile already exists!",
  ADD_PROFILE_ERR: "Unable to create a new profile, please try again!",
  NO_PAYMENT_MODE: "Please select the payment mode!",
  USER_ALREADY_SUBSCRIBED: "You have already subscribed to this plugin!",
  INITIALIZE_TRAN_ERR:
    "There is some error initializing the transaction, please try again later.",
  APPLY_COUPON_ERR: "Invalid Coupon code!",
  COUPON_CODE_EXPIRED: "Coupon Code Expired!",
  RAZORPAY_TEMP_ERROR:
    "Your purchase will be activated in a while, please contact support if you face any issues.",
  PLUGIN_PAYMENT_SUCCESS: "Your purchase has been successful.",
  PROFILE_CHANGE_SUCCESS: "Your Profile has been changed successfully!",
  GDESC_UPDATE_SUCCESS: "Group Description has been updated successfully!",
  GDESC_LENGTH_EXCEEDED: "Group Desciption can't b more than 250 characters.",
  INVALID_NUMBER: "This is an Invalid Number",
  PASSWORD_ERROR: "Password length must be between 8 digit to 20 digits! ",
};

export const LOADING_MODAL_MSG = {
  UPDATING: "Updating, Please Wait...",
  LOADING: "Loading...",
};

export const PAGE_HEADINGS = {
  HOME: "My Courses",
  JOIN_GROUP: "Join Group",
  CREATE_GROUP: "Create Group",
  MY_GROUP: "My Groups",
  MY_COURSE: "My Courses",
  EXPLORE: "Explore",
  ADD_PROFILE: "Add Profile",
  EDIT_PROFILE: "Edit Profile",
  EDIT_GROUP: "Edit Group",
  PROFILE_SETTINGS: "Profile Settings",
};
export const BUFFER_TIME = {
  ZOOM_BUFFER_END_TIME: 900000,
};
export const RAZORPAY_STATUS = {
  SUCCESS: 1,
  FAILURE: 2,
};

export const POPOVER_TYPE = {
  ERROR: 1,
  SUCCESS: 2,
};
export const SIDEBAR_WIDTH = {
  HOME_SIDEBAR: "216px",
  GROUP_SIDEBAR: "300px",
};

export const POPOVER_HIDETIME = 3000;

export const KEY_CODES = {
  ENTER_KEY: 13,
  ESC_KEY: 27,
};

export const URLS = {
  SEND_FEEDBACK_URL:
    "mailto:support@imarticus.com?subject=Feedback from Imarticus Web",
  HELP_LEARNING_URL: "https://imarticus.org/contact/",
  DEFAULT_PPIC_URL: "https://cdn.pegasus.imarticus.org/index/user_neutral.svg",
  DEFAULT_GPIC_URL: "https://cdn.pegasus.imarticus.org/index/group_default.svg",
};
export const MAIL_BODY = {
  GROUP_NOT_JOINED_START:
    "mailto:support@eckovation.com?subject=My Courses | Course Subscribed but Group not joined&body=Hi, Please help me in joining the group associated with ",
  GROUP_NOT_JOINED_END:
    ". I have subscribed to this course and it is being shown in 'My Courses' section on web page. But I am not a part of the group.\n\nStudent Name: ",
};

export const PIC_TYPE = {
  PROFILE_PIC: 1,
  GROUP_PIC: 2,
};

export const VALID_PROFILE_PIC_TYPES = ["image/png", "image/jpg", "image/jpeg"];

export const DEFAULT_IMG_URL =
  "https://cdn.pegasus.imarticus.org/images/recm_crs_thumb.png";

let _CONFIGS = {
  PATH: "/",
  DOMAIN: ".localhost",
  QUIZ_BACKEND_URL: "http://localhost:3008",
  API_API_ENDPOINT: "http://localhost:3000/",
  API_ENDPOINT: "http://localhost:3000/", //'localhost:3000/', // "https://apih.pathshala.eckovation.com/"
  ECKOVATION_WEB_ENDPOINT: "http://localhost:7015/login", //'localhost:3034/', // "https://eckovation.com/"
  ADAPTIVE_WEB_ENDPOINT: "http://localhost:3029/", //'localhost:3029/', // "https://study.eckovation.com"
  QUIZ_USER_ENDPOINT: "http://localhost:3006/",
  VIDEO_USER_ENDPOINT: "",
  DASHBOARD_URL: "/dashboard",
  COURSE_PAGE_BASE_URL: "http://localhost:3030/course/",
  WEB_DASHBOARD_ENDPOINT: "http://localhost:7015/",
  ADOBE_PDF_READER_KEY: "23fb9c0e52ca4cc99ea6e62e8688eca3",
  MY_COURSE_DISCUSSIONURL: "http://localhost:7015/dashboard/group/",
  DASHBOARD_BASE_URL: "http://localhost:7015/dashboard",
  WEB_DASHBOARD_BASE_URL: "http://localhost:7015/",
  MY_APPLICATIONS: "http://localhost:3511/applications",
  MY_TRANSACTIONS: "http://localhost:3511/transactions",
  CHANGE_PASSWORD: "http://localhost:7015/dashboard/pwd_edit",
  PAYMENT_INSTALLMENT_URL: "http://localhost:3511/installments/?crs_pg_id=",
  SOCKET_ENDPOINT: "http://localhost:3000/",
  AUTHENTICATION_COOKIES: [
    "at",
    "rt",
    "secret",
    "aid",
    "pid",
    "isLoggedIn",
    "crs_tkn",
    "cid",
    "uid",
    "s_id",
    "user",
    "ifTokenSent",
    "loaderColor",
    "ep",
  ],
  BUNNY_PULL_ZONE: "vz-29b92ae5-382.b-cdn.net",
};
let _KEYS = {
  RAZORPAY_KEY: "rzp_test_mv0CxFspk7j3qn",
};

export const NODE_ENV = process.env.NODE_ENV ? process.env.NODE_ENV : "";

export const BUILD_ENV = process.env.REACT_APP_BUILD_ENV
  ? process.env.REACT_APP_BUILD_ENV
  : "local";

if (NODE_ENV === "production" && BUILD_ENV === "testing") {
  _CONFIGS = {
    PATH: "/",
    DOMAIN: ".imarticus.org",
    QUIZ_BACKEND_URL: "https://pegasust.imarticus.org/examapi",
    API_API_ENDPOINT: "https://apiht.pegasust.imarticus.org/api/",
    API_ENDPOINT: "https://apiht.pegasust.imarticus.org/api/",
    ECKOVATION_WEB_ENDPOINT: "https://pegasust.imarticus.org/login",
    ADAPTIVE_WEB_ENDPOINT: "https://studyt.pegasust.imarticus.org",
    QUIZ_USER_ENDPOINT: "https://examft.pegasust.imarticus.org/",
    VIDEO_USER_ENDPOINT: "",
    DASHBOARD_URL: "/dashboard",
    COURSE_PAGE_BASE_URL: "https://pegasust.imarticus.org/course/",
    WEB_DASHBOARD_ENDPOINT: "https://pegasust.imarticus.org/",
    ADOBE_PDF_READER_KEY: "6475105e1e954b5d8dc0182099b441d0",
    MY_COURSE_DISCUSSIONURL: "http://pegasust.imarticus.org/dashboard/group/",
    DASHBOARD_BASE_URL: "https://pegasust.imarticus.org/dashboard",
    WEB_DASHBOARD_BASE_URL: "https://pegasust.imarticus.org/",
    MY_APPLICATIONS: "https://pegasust.imarticus.org/payments/applications",
    MY_TRANSACTIONS: "https://pegasust.imarticus.org/payments/transactions",
    CHANGE_PASSWORD: "http://pegasust.imarticus.org/dashboard/pwd_edit",
    PAYMENT_INSTALLMENT_URL:
      "https://pegasust.imarticus.org/payments/installments/?crs_pg_id=",
    SOCKET_ENDPOINT: "https://apist.pegasust.imarticus.org/",
    AUTHENTICATION_COOKIES: [
      "at",
      "rt",
      "secret",
      "aid",
      "pid",
      "isLoggedIn",
      "crs_tkn",
      "cid",
      "uid",
      "s_id",
      "user",
    ],
    BUNNY_PULL_ZONE: "vz-29b92ae5-382.b-cdn.net",
  };
} else if (NODE_ENV === "production" && BUILD_ENV === "staging") {
  _CONFIGS = {
    PATH: "/",
    DOMAIN: ".imarticus.org",
    QUIZ_BACKEND_URL: "https://pegasuss.imarticus.org/examapi",
    API_API_ENDPOINT: "https://apihs.pegasuss.imarticus.org/api/",
    API_ENDPOINT: "https://apihs.pegasuss.imarticus.org/api/",
    ECKOVATION_WEB_ENDPOINT: "https://pegasuss.imarticus.org/login",
    ADAPTIVE_WEB_ENDPOINT: "https://studys.imarticus.org",
    QUIZ_USER_ENDPOINT: "https://examfs.pegasuss.imarticus.org/",
    VIDEO_USER_ENDPOINT: "",
    DASHBOARD_URL: "/dashboard",
    COURSE_PAGE_BASE_URL: "https://www.imarticus.org/course/",
    WEB_DASHBOARD_ENDPOINT: "https://pegasuss.imarticus.org/",
    ADOBE_PDF_READER_KEY: "6475105e1e954b5d8dc0182099b441d0",
    MY_COURSE_DISCUSSIONURL: "http://pegasuss.imarticus.org/dashboard/group/",
    DASHBOARD_BASE_URL: "https://pegasuss.imarticus.org/dashboard",
    WEB_DASHBOARD_BASE_URL: "https://pegasuss.imarticus.org/",
    MY_APPLICATIONS: "https://pegasuss.imarticus.org/payments/applications",
    MY_TRANSACTIONS: "https://pegasuss.imarticus.org/payments/transactions",
    CHANGE_PASSWORD: "http://pegasuss.imarticus.org/dashboard/pwd_edit",
    PAYMENT_INSTALLMENT_URL:
      "https://pegasuss.imarticus.org/payments/installments/?crs_pg_id=",
    SOCKET_ENDPOINT: "https://apiss.pegasuss.imarticus.org/",
    AUTHENTICATION_COOKIES: [
      "at",
      "rt",
      "secret",
      "aid",
      "pid",
      "isLoggedIn",
      "crs_tkn",
      "cid",
      "uid",
      "s_id",
      "user",
    ],
    BUNNY_PULL_ZONE: "vz-29b92ae5-382.b-cdn.net",
  };

  _KEYS = {
    RAZORPAY_KEY: "rzp_live_Rq8QK3yKuu1NsX",
  };
} else if (NODE_ENV === "production" && BUILD_ENV === "production") {
  _CONFIGS = {
    PATH: "/",
    DOMAIN: ".imarticus.org",
    QUIZ_BACKEND_URL: "https://pegasus.imarticus.org/examapi",
    API_API_ENDPOINT: "https://pegasus.imarticus.org/api/",
    API_ENDPOINT: "https://pegasus.imarticus.org/api/",
    ECKOVATION_WEB_ENDPOINT: "https://pegasus.imarticus.org/login",
    ADAPTIVE_WEB_ENDPOINT: "https://study.imarticus.org",
    QUIZ_USER_ENDPOINT: "https://examf.pegasus.imarticus.org/",
    VIDEO_USER_ENDPOINT: "",
    DASHBOARD_URL: "/dashboard",
    COURSE_PAGE_BASE_URL: "https://www.imarticus.org/course/",
    WEB_DASHBOARD_ENDPOINT: "https://pegasus.imarticus.org/",
    ADOBE_PDF_READER_KEY: "6475105e1e954b5d8dc0182099b441d0",
    MY_COURSE_DISCUSSIONURL: "http://pegasus.imarticus.org/dashboard/group/",
    DASHBOARD_BASE_URL: "https://pegasus.imarticus.org/dashboard",
    WEB_DASHBOARD_BASE_URL: "https://pegasus.imarticus.org/",
    MY_APPLICATIONS: "https://pegasus.imarticus.org/payments/applications",
    MY_TRANSACTIONS: "https://pegasus.imarticus.org/payments/transactions",
    CHANGE_PASSWORD: "http://pegasus.imarticus.org/dashboard/pwd_edit",
    PAYMENT_INSTALLMENT_URL:
      "https://pegasus.imarticus.org/payments/installments/?crs_pg_id=",
    SOCKET_ENDPOINT: "https://apis.pegasus.imarticus.org/",
    AUTHENTICATION_COOKIES: [
      "at",
      "rt",
      "secret",
      "aid",
      "pid",
      "isLoggedIn",
      "crs_tkn",
      "cid",
      "uid",
      "s_id",
      "user",
    ],
    BUNNY_PULL_ZONE: "vz-61bf2950-a93.b-cdn.net",
  };

  _KEYS = {
    RAZORPAY_KEY: "rzp_live_Rq8QK3yKuu1NsX",
  };
}

let index = 0;
if (process.env.REACT_APP_BUILD_ENV == "testing") index = 1;
if (process.env.REACT_APP_BUILD_ENV == "staging") index = 2;
if (process.env.REACT_APP_BUILD_ENV == "production") index = 3;
export const LABS_REDIRECT_LINK = [
  "http://localhost:3024/Dashboard/",
  "https://labst.pegasust.imarticus.org/Dashboard/",
  "https://labss.pegasuss.imarticus.org/Dashboard/",
  "https://labs.pegasus.imarticus.org/Dashboard/",
][index];

let url = window?.location.href;
if (url) {
  let domain = new URL(url).hostname
    .split(".")
    .slice(0)
    .slice(-(url.length === 4 ? 3 : 2))
    .join(".");
  console.log({ domain });
  if (domain[0] != ".") domain = "." + domain;
  if (domain != ".imarticus.org" && domain != ".localhost") {
    let newAPIEndPoint = `${window.location.origin}/api/`;
    console.log({ newAPIEndPoint });
    _CONFIGS.DOMAIN = domain;
    _CONFIGS.API_API_ENDPOINT = newAPIEndPoint;
    _CONFIGS.WEB_DASHBOARD_ENDPOINT = `${window.location.origin}/`;
    _CONFIGS.LEARN_WEB_ENDPOINT = `${window.location.origin}/learn/`;
    _CONFIGS.MY_COURSE_BASEURL = `${window.location.origin}/learn/#/course/`;
    _CONFIGS.ECKOVATION_WEB_ENDPOINT = `${window.location.origin}/login/`;
    _CONFIGS.QUIZ_BACKEND_URL = `${window.location.origin}/examapi`;
    _CONFIGS.QUIZ_USER_ENDPOINT = `${window.location.origin}/exam/`;
    _CONFIGS.MY_COURSE_GRADEBOOKURL = `${window.location.origin}/learn/#/gradebook/`;
    _CONFIGS.MY_COURSE_DISCUSSIONURL = `${window.location.origin}/dashboard/group/`;
    _CONFIGS.DASHBOARD_BASE_URL = `${window.location.origin}/dashboard`;
    _CONFIGS.WEB_DASHBOARD_BASE_URL = `${window.location.origin}/`;
    _CONFIGS.MY_APPLICATIONS = `${window.location.origin}/payments/applications`;
    _CONFIGS.MY_TRANSACTIONS = `${window.location.origin}/payments/transactions`;
    _CONFIGS.CHANGE_PASSWORD = `${window.location.origin}/dashboard/pwd_edit`;
    _CONFIGS.PAYMENT_INSTALLMENT_URL = `${window.location.origin}/payments/installments/?crs_pg_id=`;
  }
  if (domain != ".localhost") {
    _CONFIGS.SOCKET_ENDPOINT = `${window.location.origin}/`;
  }
}
console.log({ domaininConfigs: _CONFIGS.DOMAIN });

export const TIMEZONES = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  " America/Knox_IN",
  " America/Kralendijk",
  " America/La_Paz",
  " America/Lima",
  " America/Los_Angeles",
  " America/Louisville",
  " America/Lower_Princes",
  " America/Maceio",
  " America/Managua",
  " America/Manaus",
  " America/Marigot",
  " America/Martinique",
  " America/Matamoros",
  " America/Mazatlan",
  " America/Mendoza",
  " America/Menominee",
  " America/Merida",
  " America/Metlakatla",
  " America/Mexico_City",
  " America/Miquelon",
  " America/Moncton",
  " America/Monterrey",
  " America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ujung_Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Canberra",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/NSW",
  "Australia/North",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "CET",
  "CST6CDT",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "EET",
  "EST",
  "EST5EDT",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Tiraspol",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "MST7MDT",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST8PDT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Samoa",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Pacific-New",
  "US/Samoa",
  "UTC",
  "Universal",
  "W-SU",
  "WET",
  "Zulu",
];

export const TIER_COLOR_CODE = ["#3E86A5", "#6F87BF", "#9D7075", "#E27770"];

export const COOKIE_EXPIRY_PATHS = [
  "/login",
  "/",
  "/dashboard",
  "/view",
  "/batch",
  "/gradebook",
];

export const STATIC_ROUNDS = {
  1: "Shortlisted",
  2: "Applied",
  3: "In Progress",
  4: "Selected",
  6: "Rejected",
  7: "Rejected",
};

export const CONFIGS = _CONFIGS;
export const KEYS = _KEYS;
