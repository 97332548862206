import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useHistory, withRouter } from "react-router";
import { batch, connect } from "react-redux";
import Cookies from "universal-cookie";
import { base64StringtoFile } from "../../utility/utils";
import { Modal } from "reactstrap";

import {
  apllycert,
  atCrs,
  brandDataCourse,
  genCerti,
  getGradDetails,
  getValueWeb,
  saveGradDetails,
  uploadDocumentsToAws,
  userLogout,
} from "../../actions/certificateAction.js";
import { getProfileInfoOnly } from "../../actions/profileAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import { setSelectedCourse } from "../../actions/courseAction.js";
import { CONFIGS, CCODS } from "../../constants/constants";
import { showFreshDesk } from "../../actions/showFreshDesk.js";
import SupportIcon from "../../images/Support.svg";
import ReactHtmlParser from "react-html-parser";
import SidebarContainer from "../sidebars/SidebarContainer.js";
import CertificateHeader from "../headers/certificateHeader.js";
import { ROUTES } from "../../constants/routes.js";
import Loader from "../utils/Loader.js";
import "./certificate.scss";
import { Flex } from "@aws-amplify/ui-react";
import TooltipItem from "../utils/GeneralToolTip.js";

const cookies = new Cookies();
const Certificate = (props) => {
  let num1 = 0;
  let num2 = 0;
  let query = window.location.href;
  query = query.split("/");
  const pid = query[4];
  const cid = query[5];
  const elec_id = query[6];

  const HeaderMap = {
    survey: "Quick Survey",
    details: "Student Details",
    upload: "Upload Files",
    certi: "Certificate",
  }; //keep adding here whenever a new journey is made

  const fileInputRefP = useRef(null);
  const fileInputRefID = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [batchObj, setBatchObj] = useState();
  const [brand, setBrand] = useState();
  const [studentName, setStudentName] = useState();
  const [studentPhoneNo, setStudentPhoneNo] = useState();
  const [studentEmail, setStudentEmail] = useState();
  const [studentPic, setStudentPic] = useState();
  const [profileDropDownVisible, setProfileDropDownVisible] = useState(false);
  const [batchName, setBatchName] = useState();
  const [courseName, setCourseName] = useState();
  const [npsMeterF, setNpsMeterF] = useState();
  const [followUpF, setFollowUpF] = useState();
  const [studentNameF, setStudentNameF] = useState();
  const [emailF, setEmailF] = useState();
  const [email, setEmail] = useState();
  const [linkedinF, setLinkedinF] = useState();
  const [referralEnabled, setReferralEnabled] = useState();
  const [nav, setNav] = useState([]);
  const [navCurr, setNavCurr] = useState();
  const [course, setCourse] = useState();
  const [npsMeterIndex, setNpsMeterIndex] = useState();
  const [followUpIndex, setFollowUpIndex] = useState();
  const [studentNameIndex, setStudentNameIndex] = useState();
  const [emailIndex, setEmailIndex] = useState();
  const [npsMeter, setNpsMeter] = useState(9);
  const [followUpWrong, setFollowUpWrong] = useState([]);
  const [followUpFeedback, setFollowUpFeedback] = useState();
  const [showRef, setShowRef] = useState(false);
  const [linkedInLink, setLinkedinLink] = useState();
  const [programTypes, setProgramTypes] = useState();
  const [locations, setLocations] = useState();
  const [allData, setAllData] = useState();
  const [npsColor, setNpsColor] = useState("cjr_slider_green_active");
  const [idntf, setIdntf] = useState();
  const [studentImg, setStudentImg] = useState();
  const [npsTopics, setNpsTopics] = useState();
  const [details, setDetails] = useState();
  const [linkedInIndex, setLinkedInIndex] = useState();
  const [certiLink, setCertiLink] = useState();
  const [photoF, setPhotoF] = useState();
  const [idProofF, setIdProofF] = useState();
  const [photo, setPhoto] = useState();
  const [idProof, setIdProof] = useState();
  const [step, setStep] = useState(1);
  const [refTry, setRefTry] = useState(true);
  const [refNav, setRefNav] = useState(1);
  const [refName, setRefName] = useState("");
  const [refCcod, setRefCcod] = useState("");
  const [refMobile, setRefMobile] = useState("");
  const [refEmail, setRefEmail] = useState("");
  const [refProgram, setRefProgram] = useState("");
  const [refCourse, setRefCourse] = useState("");
  const [refLocation, setRefLocation] = useState("");
  const [courses, setCourses] = useState();
  const [programKey, setProgramKey] = useState();
  const [studentImgName, setStudentImgName] = useState();
  const [idProofName, setIdProofName] = useState(); //showPopup
  const [showPopup, setShowPopup] = useState(false); //showPopup
  const [isHovered, setIsHovered] = useState(false);

  const handleInputFileClick = (type) => {
    if (type == "photo") {
      fileInputRefP.current.click();
    }
    if (type == "idProof") {
      fileInputRefID.current.click();
    }
  };

  useEffect(() => {
    (async () => {
      window.FreshworksWidget?.("hide", "launcher");
      setLoading(true);
      await authenticatCourse();
      await props.getProfileInfoOnly();
      await fetchBrandDataCrs();
      await getGraduationStatus();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (navCurr == "certi") {
        setLoading(true);
        await saveGraduationDetails();
        await generateCertificate();
      }
    })();
  }, [navCurr]);

  useEffect(() => {
    setStudentName(props.profileName);
    setStudentPhoneNo(props.contact);
    setStudentEmail(props.primaryEmail);
    setStudentPic(props.ppic);
  }, [props]);

  const generateCertificate = async () => {
    let regex = /[^\x00-\x7F]/;
    if (regex.test(props.profileName)) {
      setLoading(false);
      setShowPopup(true);
    } else {
      setLoading(true);
      genCerti(cid, pid, elec_id)
        .then(function (res) {
          setLoading(false);
          setCertiLink(res.data.data.crtf_file);
        })
        .catch(function (err) {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const authenticatCourse = async () => {
    if (!cookies.get("isLoggedIn")) {
      return window.open(CONFIGS.WEB_DASHBOARD_BASE_URL, "_self");
    }
    atCrs({ pid, cid, dispatchIndividualBrand: props.dispatchIndividualBrand })
      .then(function (res) {
        console.log(res, "crs_tkn");
        cookies.set("crs_tkn", res.data.data.at);
        let courseFound = res.data.data.course;
        setBatchObj(courseFound);
        if (res.data.data.location) {
          courseFound.loc_name = res.data.data.location;
        }
        if (courseFound) {
          props.setSelectedCourse(courseFound);
        }
        setCourse(res.data.data.course);
        setCourseName(res.data.data.course.name);
        setBatchName(res.data.data.course.btch_name);
        setNpsMeterF(res.data.data.course.certi_ques.nps_score.nps_meter);
        setFollowUpF(res.data.data.course.certi_ques.nps_score.follow_up);
        setStudentNameF(
          res.data.data.course.certi_ques.student_details.student_name
        );
        setEmailF(res.data.data.course.certi_ques.student_details.email);
        setLinkedinF(
          res.data.data.course.certi_ques.student_details.linkedIn_link
        );
        setPhotoF(res.data.data.course.certi_ques.upload_documents.photo);
        setIdProofF(res.data.data.course.certi_ques.upload_documents.id_proof);
        if (res.data.data.course.certi_ques.nps_score.nps_meter) {
          setNpsMeterIndex(num1 + 1);
          num1++;
        }
        if (res.data.data.course.certi_ques.nps_score.follow_up) {
          setFollowUpIndex(num1 + 1);
          num1++;
        }
        if (res.data.data.course.certi_ques.student_details.student_name) {
          setStudentNameIndex(num2 + 1);
          num2++;
        }
        if (res.data.data.course.certi_ques.student_details.email) {
          setEmailIndex(num2 + 1);
          num2++;
        }

        if (res.data.data.course.certi_ques.student_details.linkedIn_link) {
          setLinkedInIndex(num2 + 1);
          num2++;
        }

        setReferralEnabled(
          res.data.data.course.referral_enabled &&
            res.data.data.course.certi_ques.nps_score.nps_meter
        );
        let tempNav = [];
        console.log(tempNav, "crs_tkn");
        tempNav.unshift("certi");
        if (
          res.data.data.course.certi_ques.upload_documents.photo ||
          res.data.data.course.certi_ques.upload_documents.id_proof
        ) {
          tempNav.unshift("upload");
        }
        if (
          res.data.data.course.certi_ques.student_details.student_name ||
          res.data.data.course.certi_ques.student_details.linkedIn_link ||
          res.data.data.course.certi_ques.student_details.email
        ) {
          tempNav.unshift("details");
        }
        if (
          res.data.data.course.certi_ques.nps_score.nps_meter ||
          res.data.data.course.certi_ques.nps_score.follow_up
        ) {
          tempNav.unshift("survey");
        }
        setNavCurr(tempNav[0]);
        changeMeterColour();
        console.log({ tempNav });
        setNav(tempNav);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const fetchBrandDataCrs = async () => {
    brandDataCourse(cid)
      .then((response) => {
        var brand = response.data?.data?.brand;
        // props.dispatchIndividualBrand(brand);
        if (brand) {
          setBrand(brand);
        } else {
          setBrand({});
          props.setBrand(null);
        }
      })
      .catch((err) => {
        props.dispatchIndividualBrand();
        setBrand({});
        console.log({ err });
      });
  };

  const redirectToDashboard = function () {
    window.location.replace(CONFIGS.WEB_DASHBOARD_BASE_URL);
  };

  const toggleProfileDropDown = async function () {
    setProfileDropDownVisible(() => {
      return !profileDropDownVisible;
    });
  };

  const logOut = async function () {
    console.log("logging out");
    userLogout()
      .then(function (res) {
        cookies.remove("at", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("secret", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("aid", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("rt", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("isLoggedIn", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("pid", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("pid", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
        cookies.remove("crs_tkn", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });

        window.open(CONFIGS.ECKOVATION_WEB_ENDPOINT, "_self");
      })
      .catch(function (err) {
        console.log(err);
        alert("Something went wrong");
      });
  };

  const checkNext = function (value) {
    if (value === "survey") {
      let tempFollowUpFeedback = followUpFeedback;
      if (followUpFeedback) {
        tempFollowUpFeedback = followUpFeedback.trim();
      }

      if (npsMeterF && !(npsMeter >= 0)) {
        return "Please fill NPS Meter";
      }
      if (npsMeterF && npsMeter <= 8 && followUpWrong.length === 0) {
        return "Please fill what went wrong";
      }
      if (
        followUpF &&
        ((tempFollowUpFeedback && tempFollowUpFeedback.length == 0) ||
          !tempFollowUpFeedback)
      ) {
        return "Please fill your suggestions";
      }
      if (referralEnabled && npsMeter >= 9) {
        setShowRef(true);
        getValue();
      } else {
        setShowRef(false);
      }
    }

    if (value === "details") {
      if (studentNameF && !studentName) {
        return "Please fill your Name";
      }

      if (emailF && !studentEmail) {
        return "Please fill your Email";
      }

      if (emailF && !validEmail(studentEmail)) {
        return "Please Enter a Valid Email";
      }

      if (linkedinF && !linkedInLink) {
        return "Please Enter you LinkedIn Link";
      }

      if (linkedinF && !validLinkedIn(linkedInLink)) {
        return "Please Enter a Valid LinkedIn Link";
      }
    }

    if (value == "upload") {
      if (photoF && !(photo || studentImg)) {
        return "Please upload your Photo.";
      }
      if (idProofF && !(idProof || idntf)) {
        return "Please upload your Identity Proof.";
      }
    }

    // Pending for upload

    return 0;
  };

  const getValue = function () {
    getValueWeb().then(function (res) {
      if (res.data.data) {
        setProgramTypes(res.data.data.programTypes);
        setLocations(res.data.data.locations);
        setAllData(res.data.data.allData);
      }
    });
  };

  const validEmail = function (email) {
    var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const validLinkedIn = function (link) {
    var linkedinRegex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/;
    return linkedinRegex.test(link);
  };

  const changeMeter = function (value) {
    setNpsMeter(value);
    changeMeterColour(value);
  };

  const changeMeterColour = function (npsMeter) {
    if (npsMeter >= 9) setNpsColor("cjr_slider_green_active");
    if (npsMeter >= 7 && npsMeter < 9)
      setNpsColor("cjr_slider_light_green_active");
    if (npsMeter >= 0 && npsMeter < 7) setNpsColor("cjr_slider_red_active");
  };

  const saveGraduationDetails = function () {
    return new Promise((resolve, reject) => {
      var configs = {};
      var headers = {};
      headers["x-access-crs-token"] = cookies.get("crs_tkn");
      configs.headers = headers;
      let data = {
        crs_id: cid,
        fdbk: followUpFeedback,
        rtng: npsMeter,
        idntf: idntf,
        img: studentImg,
        email: studentEmail,
        p_id: pid,
        a_id: cookies.get("aid"),
        lkdn: linkedInLink,
        usr_crt_nme: studentName,
        follow_up_wrong: followUpWrong,
      };
      if (elec_id && elec_id != "undefined") {
        data["elec_id"] = elec_id;
      }
      axios
        .post(ROUTES.UPLOAD_GRADUATION_DETAIL, data, configs)
        .then(function (res) {
          return resolve(res, null);
        })
        .catch(function (err) {
          console.log(err);
          return reject(null, err);
        });
    });
  };

  const getGraduationStatus = async function () {
    getGradDetails(pid, cid, elec_id)
      .then(function (res) {
        console.log(res, res.data.data.details);
        var tempFollowUpWrong = res.data.data.details.follow_up_wrong;
        setFollowUpFeedback(res.data.data.details.fdbk);
        if (res.data.data.details.rtng) {
          setNpsMeter(parseInt(res.data.data.details.rtng));
          changeMeterColour(parseInt(res.data.data.details.rtng));
        }
        if (tempFollowUpWrong && tempFollowUpWrong.length > 0) {
          setFollowUpWrong([...tempFollowUpWrong]);
        }
        setIdProof(res.data.data.details.idnt);
        setPhoto(res.data.data.details.img);
        setLinkedinLink(res.data.data.details.lkdn);
        setNpsTopics(res.data.data.nps);
        setDetails(res.data.data.details);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const followUpSelect = (option) => {
    if (!followUpWrong.includes(option)) {
      setFollowUpWrong([...followUpWrong, option]);
    } else {
      setFollowUpWrong(followUpWrong.filter((item) => item !== option));
    }
  };

  const changeFeedbackValue = (value) => {
    setFollowUpFeedback(value);
  };

  const forward = async function (value) {
    var res = checkNext(value);
    setLoading(true);
    await saveGraduationDetails();
    setLoading(false);
    if (!res) {
      if (nav[nav.indexOf(value) + 1] == "certi") {
        let regex = /[^\x00-\x7F]/;
        if (regex.test(props.profileName)) {
          setShowPopup(true);
          return;
        }
      }
      setNavCurr(nav[nav.indexOf(value) + 1]);
      var tempStep = nav.indexOf(value) + 2;
      setStep(tempStep);
    } else {
      alert(res);
    }
  };

  const uploadDocuments = async function (document, type) {
    console.log({ document });
    const validTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    let validFile = true;
    if (!validTypes.includes(document.type)) {
      validFile = false;
      window.alert(
        "Invalid file type. Please select a PNG, JPEG, JPG or PDF file."
      );
      return;
    }
    if (document.size > maxSize) {
      window.alert("File size exceeds the maximum limit of 5MB.");
      return;
    }
    if (validFile) {
      setLoading(true);
      uploadDocumentsToAws(document)
        .then(({ data }) => {
          console.log({ DataInAxiosThen: data });
          if (type == "photo") {
            setStudentImg(data.data);
            setPhoto(data.data);
            setStudentImgName(document.name);
          }
          if (type == "idProof") {
            setIdntf(data.data);
            setIdProof(data.data);
            setIdProofName(document.name);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log("error in adding brand action ::: ", e);
          setLoading(false);
        });
    }
  };

  const backward = function (value) {
    if (navCurr === nav[0]) {
      history.goBack();
    }
    setNavCurr(nav[nav.indexOf(value) - 1]);
    setStep(nav.indexOf(value));
  };

  const changeName = function (e) {
    setStudentName(e);
  };

  const changeEmail = function (e) {
    setEmail(e);
  };

  const changeLinkedIn = function (e) {
    setLinkedinLink(e);
  };

  const applyCerti = async function () {
    setLoading(true);
    apllycert(cid, pid, elec_id)
      .then(function (res) {
        // setCertiLink(res.data.data.crtf_file);
        // setLoading(false);
        // window.open(res.data.data.crtf_file, "_blank");
        if (res.data.data) {
          let cert = res.data.data;
          if (elec_id && elec_id != "undefined") {
            console.log("----------elec_id", elec_id);
            if (elec_id && cert[elec_id] && cert[elec_id].status) {
              let obj = cert[elec_id].data;

              setCertiLink(obj.crtf_file);
              setLoading(false);
              window.open(obj.crtf_file, "_blank");
            }
          } else {
            if (cert["FOUNDATION"] && cert["FOUNDATION"].status) {
              let obj = cert["FOUNDATION"].data;

              setCertiLink(obj.crtf_file);
              setLoading(false);
              window.open(obj.crtf_file, "_blank");
            }
          }
        }
      })
      .catch(function (err) {
        setLoading(false);
        console.log(err);
      });
  };

  const closeRefer = function () {
    setRefNav(1);
    setShowRef(false);
    setRefTry(false);
  };

  const refNext = (nav) => {
    setRefNav(nav);
  };

  const refNameHandler = (e) => {
    setRefName(e.target.value);
  };

  const refCcodHandler = (e) => {
    setRefCcod(e.target.value);
  };

  const refMobileHandler = (e) => {
    setRefMobile(e.target.value);
  };

  const refEmailHandler = (e) => {
    setRefEmail(e.target.value);
  };

  const refProgramHandler = (e) => {
    setRefProgram(e.target.value);
    getCourseof(e.target.value);
  };

  const refCourseHandler = (e) => {
    setRefCourse(e.target.value);
    getLsProgramKey(e.target.value);
  };

  const refLocationHandler = (e) => {
    setRefLocation(e.target.value);
  };

  const getCourseof = function (value) {
    let tempCourses = [];
    console.log(value);
    allData.forEach(function (elem) {
      elem.tag.forEach(function (t) {
        if (t) {
          if (t.toUpperCase() === value.toUpperCase()) {
            tempCourses.push(elem);
          }
        }
      });
    });
    setCourses(tempCourses);
  };

  const getLsProgramKey = function (value) {
    let tempProgramKey;
    courses.forEach((course) => {
      if (course && course.programName) {
        if (value == course.programName) {
          tempProgramKey = course.leadsquareProgramKey;
        }
      }
    });
    setProgramKey(tempProgramKey);
  };

  useEffect(() => {
    console.log({ programKey });
  }, [programKey]);

  const refSubmit = async function () {
    setLoading(true);
    const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const mobReg = /^\d{6,15}$/;
    console.log(!emailReg.test(refEmail), !refEmail.length <= 60);
    if (
      refName === "" ||
      refMobile === "" ||
      refEmail === "" ||
      refProgram === "" ||
      refCourse === "" ||
      refLocation === "" ||
      refCcod === ""
    ) {
      alert("Please fill in all details");
      setLoading(false);
    } else if (
      !(refEmail.length <= 60) ||
      !emailReg.test(refEmail.toLowerCase())
    ) {
      console.log({ refEmail });
      alert("Please enter a valid Email Id");
      setLoading(false);
    } else if (!mobReg.test(refMobile)) {
      alert("Please enter a valid Mobile number");
      setLoading(false);
    } else {
      var device = "";
      var windowWidth;
      if (typeof window !== "undefined") {
        windowWidth = parseInt(window.innerWidth);
      }
      if (windowWidth > 1200) device = "Desktop";
      else if (windowWidth > 520) device = "Tablet";
      else device = "Mobile";

      var configs = {};
      var headers = {};
      headers["x-access-token"] = cookies.get("at");
      configs.headers = headers;
      axios
        .post(
          ROUTES.CREATE_REFERRAL,
          {
            FirstName: refName,
            Phone: refMobile ? refMobile.toString() : "",
            CountryCode: refCcod,
            EmailAddress: refEmail,
            program_name: refCourse,
            aid_of_submitter: cookies.get("aid"),
            referred_program_type: refProgram,
            mx_Training_Location: refLocation,
            referral_mode: "student",
            mx_Program_from_website: programKey,
            otp_verification_time: new Date().getTime(),
            referral_endpoint: "Graduation",
            referrer_name: studentName,
            referrer_email: studentEmail,
            referrer_phone: studentPhoneNo || "",
            referrer_countryCode: "",
            referral_device: device,
            Source: "Referral",
            leadSource: "Referral",
            mx_Program_3: programKey,
          },
          configs
        )
        .then(function (res) {
          setRefName("");
          setRefMobile("");
          setRefEmail("");
          setRefProgram("");
          setRefCourse("");
          setRefLocation("");
          setRefCcod("");
          setProgramKey("");
          setRefNav(3);
          setLoading(false);
        })
        .catch(function (err) {
          alert(err.response.data.message || "Something went wrong");
          setLoading(false);
        });
    }
  };

  const downloadFile = async function (id, url) {
    setLoading(true);
    var linkSource = "",
      downloadLink = "",
      fileName = "";
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        var i = "",
          enc = "";
        var file = res.data;
        i = file.indexOf(",");
        enc = file.slice(i + 1);
        var blob = b64toBlob(enc, res.headers["content-type"], 512);
        var blobUrl = URL.createObjectURL(blob);
        linkSource = blobUrl;
        downloadLink = document.getElementById(id);
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        window.open(downloadLink, "_blank");
      })
      .catch((error) => {
        setLoading(false);
        linkSource = url;
        downloadLink = document.getElementById(id);
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        window.open(downloadLink, "_blank");
      });
  };

  const b64toBlob = function (b64Data, contentType, sliceSize, event) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    console.log(blob);
    return blob;
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="certificatePage">
      {showRef && refTry && <div className="blurred-bg"></div>}
      {showRef && refTry && (
        <div
          className="cjr_refer_main"
          style={{
            position: "absolute",
            zIndex: 1000,
            top: "20vh",
            left: "35vw",
          }}
        >
          {refNav == 1 && (
            <div className="cjr_refer_1">
              <div>
                <img
                  src="https://cdn.pegasus.imarticus.org/certificates/Word_of_mouth_marketing_2_.svg"
                  alt="world"
                />
              </div>
              <div className="cjr_refer_head">Refer & Earn!</div>
              <div className="cjr_refer_body">
                Looks like you really enjoyed this course. Refer someone you
                know and get Cash Reward upto ₹10,000!
              </div>

              <div className="cjr_next cjr_next_mob" onClick={() => refNext(2)}>
                Refer Now
              </div>
              <div className="cjr_later" onClick={closeRefer}>
                Later
              </div>
            </div>
          )}

          {refNav === 2 && (
            <div className="cjr_refer_2">
              <div className="cjr_refer_2_head">
                <div></div>
                <div>Enter your friend's details</div>
                <div>
                  <img
                    style={{ cursor: "pointer" }}
                    src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
                    alt="cross"
                    onClick={closeRefer}
                  />
                </div>
              </div>
              <div className="cjr_ref_input_main">
                <input
                  className="cjr_ref_input"
                  type="text"
                  placeholder="Full Name"
                  value={refName}
                  onChange={refNameHandler}
                />
                <select
                  className="cjr_ref_input"
                  value={refCcod}
                  onChange={refCcodHandler}
                >
                  <option value="">Select Country Code</option>
                  {CCODS.map((ccod, index) => (
                    <option key={index} value={ccod.value}>
                      {ccod.display}
                    </option>
                  ))}
                </select>
                <input
                  className="cjr_ref_input"
                  type="Number"
                  placeholder="Mobile Number"
                  value={refMobile}
                  onChange={refMobileHandler}
                />
                <input
                  className="cjr_ref_input"
                  type="text"
                  placeholder="Email ID"
                  value={refEmail}
                  onChange={refEmailHandler}
                />
                <select
                  className="cjr_ref_input"
                  value={refProgram}
                  onChange={refProgramHandler}
                >
                  <option value="">Program Types</option>
                  {programTypes.map((elem, index) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  ))}
                </select>
                <select
                  className="cjr_ref_input"
                  value={refCourse}
                  onChange={refCourseHandler}
                >
                  <option value="">Course</option>
                  {courses &&
                    courses.length > 0 &&
                    courses.map((elem, index) => (
                      <option key={index} value={elem.programName}>
                        {elem.programName}
                      </option>
                    ))}
                </select>
                <select
                  className="cjr_ref_input"
                  value={refLocation}
                  onChange={refLocationHandler}
                >
                  <option value="">Location</option>
                  {locations.map((elem, index) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  ))}
                </select>
              </div>
              <div className="cjr_center">
                <div className="cjr_submit_ref" onClick={refSubmit}>
                  Submit
                </div>
              </div>
            </div>
          )}

          {refNav === 3 && (
            <div className="cjr_refer_1">
              <div>
                <img
                  src="https://cdn.pegasus.imarticus.org/certificates/refer.svg"
                  alt="world"
                />
              </div>
              <div className="cjr_refer_head">Thank you for referring!</div>
              <div className="cjr_refer_body">
                If the referral is a valid lead and if he/she enrols in the
                program then you will be entitled a cash reward from Imarticus.
              </div>
              <div className="cjr_refer_last">
                <div className="cjr_back" onClick={() => refNext(1)}>
                  Refer More
                </div>
                <div className="cjr_next" onClick={closeRefer}>
                  Done
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
        <Modal
          isOpen={showPopup}
          centered={true}
          style={{
            width: "800px",
            height: "444px",
            position: "relative",
            zIndex: 1000,
            left: "-8vw",
            borderRadius: "8px",
          }}
        >
          <div
            className="cert-popup-text"
            style={{
              display: "flex",
              flexDirection: "column",
              // alignItems: 'center',
              // justifyContent: 'center',
              marginTop: "32px",
              marginBottom: "32px",
              marginLeft: "48px",
              marginRight: "48px",
            }}
          >
            <div
              style={{
                // marginLeft: "32px",
                marginBottom: "8px",
                color: "#A09FA0",
                textAlign: "center",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                textTransform: "uppercase",
                fontFamily: "Source Sans Pro",
                lineHeight: "120%",
              }}
            >
              Certificate generation failed!
            </div>

            <div
              style={{
                // marginLeft: "32px",
                marginTop: "8px",
                color: "#3C4852",
                textAlign: "center",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                // lineHeight: '32px',
                fontFamily: "Source Sans Pro",
                lineHeight: "120%",
              }}
            >
              Special characters detected in your name. Please update your
              profile to edit your name for certificate generation.
            </div>
            <div style={{ margin: "32px" }}>
              <img
                src="https://cdn.pegasus.imarticus.org/courses/images/not_found.webp"
                alt="success gif"
                style={{ maxWidth: "314px", minHeight: "227px" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              // marginTop: "32px",
              marginBottom: "32px",
              // marginLeft: '48px',
              // marginRight: '48px'
            }}
          >
            <button
              style={{
                padding: "2%",
                border: "solid",
                fontSize: "18px",
                fontWeight: 600,
                textAlign: "center",
                width: "180px",
                cursor: "pointer",
                borderRadius: "12px",
                borderColor: "var(--themeColor)",
                color: isHovered ? "white" : "var(--themeColor)",
                backgroundColor: isHovered ? "var(--themeColor)" : "white",
                fontFamily: "Source Sans Pro",
                lineHeight: "120%",
              }}
              onClick={() => {
                window.location.replace(
                  `${CONFIGS.WEB_DASHBOARD_BASE_URL}dashboard/edit`
                );
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Edit Profile
            </button>
          </div>
        </Modal>
      </div>

      <div className="cjr_main">
        <div className="cjr_main_body">
          <div className="rvm-v1-header center-all">
            <div className="rvm-v1-header-pc-itm-ctnr">
              <div className="rvm-v1-header-pc-itm-ctnr-1">
                <span className="rvm-v1-header-brdsc-active">
                  <div
                    className="rvm-v1-sidebar-logo-ctnr center-all"
                    style={{ boxShadow: "none", cursor: "pointer" }}
                  >
                    {brand != undefined && (
                      <img
                        onClick={() => redirectToDashboard()} // Assuming redirectToDashboard is a function
                        src={
                          Object.keys(brand).length > 0
                            ? brand.pic
                            : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
                        }
                        alt="Logo"
                      />
                    )}
                  </div>
                </span>

                {studentName && (
                  <div className="profile-btns">
                    <ul className="navbar-right-top">
                      {brand != undefined &&
                        (Object.keys(brand).length == 0 ||
                          (brand && brand.getHelpEnabled)) && (
                          <li className="nav-item header-get-help-container">
                            <button
                              className="header-get-help"
                              onClick={() =>
                                showFreshDesk(
                                  [batchObj],
                                  studentPhoneNo,
                                  studentName,
                                  studentEmail
                                )
                              }
                            >
                              <img
                                src={
                                  "https://cdn.pegasus.imarticus.org/index/help_neutral.svg"
                                }
                              />
                              <span
                                className="header-get-help-text"
                                style={{ fontWeight: "400" }}
                              >
                                Get help
                              </span>
                            </button>
                          </li>
                        )}
                      <li
                        className="nav-item dropdown profileDropDownButton"
                        onClick={() => toggleProfileDropDown()}
                      >
                        {" "}
                        {/* Assuming toggleProfileDropDown is a function */}
                        <button
                          className="profile-pic"
                          href="#"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src={studentPic}
                            alt="user"
                            className="profile-img"
                          />
                          <span className="profile-dropdown-menu-profile-name">
                            {studentName}{" "}
                            <i
                              className="icon fa fa-chevron-down"
                              style={{ fontSize: "small" }}
                            ></i>
                          </span>
                        </button>
                        {profileDropDownVisible && (
                          <div className="profile-dropdown-menu">
                            {!brand ||
                              ((Object.keys(brand).length == 0 ||
                                brand.editProfileEnabled) && (
                                <a
                                  href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}dashboard/edit`}
                                  className="profile-dropdown-list-item text-none"
                                >
                                  Edit Profile
                                </a>
                              ))}
                            {!brand ||
                              (Object.keys(brand).length == 0 && (
                                <a
                                  href={`${CONFIGS.MY_APPLICATIONS}`}
                                  className="profile-dropdown-list-item text-none"
                                >
                                  My Applications
                                </a>
                              ))}
                            {!brand ||
                              (Object.keys(brand).length == 0 && (
                                <a
                                  href={`${CONFIGS.MY_TRANSACTIONS}`}
                                  className="profile-dropdown-list-item text-none"
                                >
                                  My Transactions
                                </a>
                              ))}
                            <span
                              className="profile-dropdown-list-item"
                              onClick={() => logOut()}
                            >
                              Logout
                            </span>{" "}
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="width-100 center rvm-v1-header-mbl-menu-icn-ctnr">
                {brand != undefined && (
                  <img
                    onClick={() => redirectToDashboard()} // Assuming redirectToDashboard is a function
                    className="rvm-v1-header-mbl-img"
                    src={
                      Object.keys(brand).length > 0
                        ? brand.pic
                        : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
                    }
                    alt="Logo"
                  />
                )}
                {studentName && (
                  <div className="profile-btns">
                    <ul className="navbar-right-top">
                      {brand != undefined &&
                        (Object.keys(brand).length == 0 ||
                          (brand && brand.getHelpEnabled)) && (
                          <li className="nav-item header-get-help-container">
                            <button
                              className="header-get-help"
                              onClick={() =>
                                showFreshDesk(
                                  [batchObj],
                                  studentPhoneNo,
                                  studentName,
                                  studentEmail
                                )
                              }
                            >
                              <img
                                src={
                                  "https://cdn.pegasus.imarticus.org/index/help_neutral.svg"
                                }
                              />
                              <span className="header-get-help-text">
                                Get help
                              </span>
                            </button>
                          </li>
                        )}
                      <li
                        className="nav-item dropdown profileDropDownButton"
                        onClick={() => toggleProfileDropDown()}
                      >
                        <button
                          className="profile-pic"
                          href="#"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src={studentPic}
                            alt="user"
                            className="profile-img"
                          />
                          <span className="profile-dropdown-menu-profile-name">
                            {studentName}
                            <i
                              className="icon fa fa-chevron-down"
                              style={{ fontSize: "small" }}
                            ></i>
                          </span>
                        </button>

                        {profileDropDownVisible && (
                          <div className="profile-dropdown-menu">
                            {brand != undefined &&
                              (Object.keys(brand).length == 0 ||
                                (brand && brand.editProfileEnabled)) && (
                                <a
                                  href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}edit`}
                                  className="profile-dropdown-list-item text-none"
                                >
                                  Edit Profile
                                </a>
                              )}
                            {brand != undefined &&
                              Object.keys(brand).length == 0 && (
                                <a
                                  href={`${CONFIGS.MY_APPLICATIONS}`}
                                  className="profile-dropdown-list-item text-none"
                                >
                                  My Applications
                                </a>
                              )}
                            {brand != undefined &&
                              Object.keys(brand).length == 0 && (
                                <a
                                  href={`${CONFIGS.MY_TRANSACTIONS}`}
                                  className="profile-dropdown-list-item text-none"
                                >
                                  My Transactions
                                </a>
                              )}
                            <span
                              className="profile-dropdown-list-item"
                              onClick={() => logOut()}
                            >
                              Logout
                            </span>
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`progress-container ${nav.length == 1 ? "one-nav" : ""}`}
          >
            <div className="circle-container">
              {nav &&
                nav.length > 0 &&
                nav.map((value, index) => {
                  return (
                    <div
                      key={value}
                      className={`${
                        index <= step - 1
                          ? "cjr_nav_child cjr_nav_child_active"
                          : "cjr_nav_child"
                      } ${index == 0 ? "start-nav" : ""} ${
                        index > 0 && index == nav.length - 1 ? "last-nav" : ""
                      }`}
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className={
                          index <= step - 1
                            ? "cjr_nav_child_dot_active"
                            : "cjr_nav_child_dot"
                        }
                      ></div>
                      <div className="progress-nav-header">
                        {HeaderMap[value]}
                      </div>
                    </div>
                  );
                })}
            </div>
            {nav && nav.length > 1 && (
              <div
                className="bar"
                style={{
                  width: `${
                    ((step - 1) /
                      (nav && nav.length > 1 ? nav.length - 1 : 1)) *
                      100 +
                    2
                  }%`,
                }}
              ></div>
            )}
          </div>
          <div className="cjr_container">
            <div className="cjr_body">
              {navCurr === "survey" && (
                <div>
                  {navCurr === "survey" && npsMeterF && (
                    <div className="cjr_nps_meter">
                      <div className="cjr_body_head">
                        {npsMeterIndex}. How likely are you to recommend this
                        course to a friend or colleague on a scale of 0 - 10?
                      </div>
                      <div className="cjr_slider_main">
                        <div className="cjr_slider_head">
                          Slide left or right to rate
                        </div>
                        <div className="cjr_slider">
                          <div
                            className={`cjr_slider_red ${
                              npsMeter >= 0 ? npsColor : ""
                            } cjr_slider_first`}
                            onClick={() => changeMeter(0)}
                          >
                            0
                          </div>
                          <div
                            className={`cjr_slider_red ${
                              npsMeter >= 1 ? npsColor : ""
                            }`}
                            onClick={() => changeMeter(1)}
                          >
                            1
                          </div>
                          <div
                            className={`cjr_slider_red ${
                              npsMeter >= 2 ? npsColor : ""
                            }`}
                            onClick={() => changeMeter(2)}
                          >
                            2
                          </div>
                          <div
                            className={`cjr_slider_red ${
                              npsMeter >= 3 ? npsColor : ""
                            }`}
                            onClick={() => changeMeter(3)}
                          >
                            3
                          </div>
                          <div
                            className={`cjr_slider_red ${
                              npsMeter >= 4 ? npsColor : ""
                            }`}
                            onClick={() => changeMeter(4)}
                          >
                            4
                          </div>
                          <div
                            className={`cjr_slider_red ${
                              npsMeter >= 5 ? npsColor : ""
                            }`}
                            onClick={() => changeMeter(5)}
                          >
                            5
                          </div>
                          <div
                            className={`cjr_slider_red ${
                              npsMeter >= 6 ? npsColor : ""
                            }`}
                            onClick={() => changeMeter(6)}
                          >
                            6
                          </div>
                          <div
                            className={`cjr_slider_light_green ${
                              npsMeter >= 7 ? npsColor : ""
                            }`}
                            onClick={() => changeMeter(7)}
                          >
                            7
                          </div>
                          <div
                            className={`cjr_slider_light_green ${
                              npsMeter >= 8 ? npsColor : ""
                            }`}
                            onClick={() => changeMeter(8)}
                          >
                            8
                          </div>
                          <div
                            className={`cjr_slider_green ${
                              npsMeter >= 9 ? npsColor : ""
                            }`}
                            onClick={() => changeMeter(9)}
                          >
                            9
                          </div>
                          <div
                            className={`cjr_slider_green ${
                              npsMeter >= 10 ? npsColor : ""
                            } cjr_slider_last`}
                            onClick={() => changeMeter(10)}
                          >
                            10
                          </div>
                        </div>
                        <div className="cjr_slider_footer">
                          <div>Not at all likely</div>
                          <div>Extremely likely</div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="cjr_follow_up_ques">
                    {npsMeter < 9 && (
                      <>
                        <div className="cjr_body_head">
                          - Please tell us what went wrong?
                        </div>
                        <ul className="cjr_wrong">
                          <li
                            onClick={() =>
                              followUpSelect("App Course Interface")
                            }
                            className={
                              followUpWrong.includes("App Course Interface")
                                ? "cjr_wrong_active"
                                : ""
                            }
                          >
                            App Course Interface
                          </li>
                          <li
                            onClick={() => followUpSelect("Video Quality")}
                            className={
                              followUpWrong.includes("Video Quality")
                                ? "cjr_wrong_active"
                                : ""
                            }
                          >
                            Video Quality
                          </li>
                          <li
                            onClick={() => followUpSelect("Teaching Method")}
                            className={
                              followUpWrong.includes("Teaching Method")
                                ? "cjr_wrong_active"
                                : ""
                            }
                          >
                            Teaching Method
                          </li>
                          <li
                            onClick={() =>
                              followUpSelect("Course Topic Covered")
                            }
                            className={
                              followUpWrong.includes("Course Topic Covered")
                                ? "cjr_wrong_active"
                                : ""
                            }
                          >
                            Course Topic Covered
                          </li>
                          <li
                            onClick={() => followUpSelect("Others")}
                            className={
                              followUpWrong.includes("Others")
                                ? "cjr_wrong_active"
                                : ""
                            }
                          >
                            Others
                          </li>
                        </ul>
                      </>
                    )}
                    {navCurr == "survey" && followUpF && (
                      <div>
                        <div className="cjr_body_head">
                          {followUpIndex}. Please share your feedback and
                          suggestions.
                        </div>
                        <textarea
                          className="cjr_feedback_text"
                          placeholder="Please share your feedback & suggestions"
                          value={followUpFeedback}
                          onChange={(e) => changeFeedbackValue(e.target.value)}
                        ></textarea>
                      </div>
                    )}
                  </div>

                  <div className="cjr_control_buttons">
                    {!(navCurr == nav[0]) && (
                      <div
                        className="cjr_back"
                        onClick={() => backward("survey")}
                      >
                        Back
                      </div>
                    )}
                    <div className="cjr_next" onClick={() => forward("survey")}>
                      Next
                    </div>
                  </div>
                </div>
              )}

              <div className="cjr_student_details">
                {navCurr === "details" && (
                  <>
                    {studentNameF && (
                      <div className="cjr_space cjr_student_name">
                        <div className="cjr_body_head">
                          {studentNameIndex}. Your Name
                        </div>
                        <div className="cjr_body_description">
                          <div>
                            Please make sure your name is correct. Once the
                            certificate is generated, the name on it cannot be
                            changed.{" "}
                            <TooltipItem
                              id="namePopup"
                              batch="If your name is incorrect then go to Edit profile  and  edit your name"
                            />
                          </div>
                        </div>
                        <input
                          className="cjr_body_input"
                          type="text"
                          placeholder="Name..."
                          value={studentName}
                          onChange={(e) => changeName(e.target.value)}
                          disabled
                        />
                      </div>
                    )}

                    {emailF && (
                      <div className="cjr_space cjr_email">
                        <div className="cjr_body_head">
                          {emailIndex}. Your Email id
                        </div>
                        <div className="cjr_body_description">
                          Please make sure your email is correct.
                        </div>
                        <input
                          className="cjr_body_input"
                          type="text"
                          placeholder="Email..."
                          value={studentEmail}
                          onChange={(e) => changeEmail(e.target.value)}
                          disabled
                        />
                      </div>
                    )}
                    {linkedinF && (
                      <div className="cjr_space cjr_linkedIn_url">
                        <div className="cjr_body_head">
                          {linkedInIndex}. Your Linked URL
                        </div>
                        <div className="cjr_body_description">
                          For verification purposes, please provide your
                          LinkedIn account URL
                        </div>
                        <input
                          className="cjr_body_input"
                          type="text"
                          placeholder="Enter your linkedln profile url"
                          value={linkedInLink || ""}
                          onChange={(e) => changeLinkedIn(e.target.value)}
                        />
                      </div>
                    )}
                    <div className="cjr_control_buttons">
                      {!(navCurr == nav[0]) && (
                        <div
                          className="cjr_back"
                          onClick={() => backward("details")}
                        >
                          Back
                        </div>
                      )}
                      <div
                        className="cjr_next"
                        onClick={() => forward("details")}
                      >
                        Next
                      </div>
                    </div>
                  </>
                )}
              </div>
              {navCurr == "upload" && (
                <div className="cjr_student_details">
                  {navCurr === "upload" && photoF && (
                    <div className="cjr_space cjr_student_name">
                      <div className="cjr_body_head">1. Upload your photo</div>
                      <div className="cjr_body_description">
                        The photo you'll upload must be clear. We'll use that
                        image in our website for testimonial purpose.
                      </div>
                      <div
                        className="cjr_body_input_file main_header_input"
                        onClick={() => {
                          handleInputFileClick("photo");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <span
                            style={{
                              color: "#3C4852",
                              cursor: "pointer",
                              fontWeight: "700",
                            }}
                          >
                            Click to upload your file here
                          </span>{" "}
                          <input
                            type="file"
                            accept="image/*,.pdf"
                            ref={fileInputRefP}
                            id="photo"
                            hidden
                            className="attach"
                            onChange={(e) => {
                              uploadDocuments(e.target.files[0], "photo");
                            }}
                          />
                          {studentImg && <div>{studentImgName}</div>}
                          {photo && !studentImg && (
                            <div
                              style={{ color: "blue", cursor: "pointer" }}
                              className="testimonial-record"
                              onClick={(e) => {
                                e.stopPropagation();
                                downloadFile(`${photo}`, photo);
                              }}
                            >
                              Photo Uploaded
                              <a id={`${photo}`}></a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {navCurr === "upload" && idProofF && (
                    <div className="cjr_space cjr_email">
                      <div className="cjr_body_head">
                        {photoF ? 2 : 1}. Upload your Valid ID Proof
                      </div>
                      <div className="cjr_body_description">
                        For verification purposes, we need your valid ID. Max
                        size 5 MB.
                      </div>
                      <div
                        className="cjr_body_input_file main_header_input"
                        onClick={() => {
                          handleInputFileClick("idProof");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="">
                          <span
                            style={{
                              color: "#3C4852",
                              cursor: "pointer",
                              fontWeight: "700",
                            }}
                          >
                            Click to upload your file here
                          </span>{" "}
                          <input
                            type="file"
                            accept="image/*,.pdf"
                            ref={fileInputRefID}
                            id="idProof"
                            style={{ display: "none" }}
                            className="attach"
                            onChange={(e) => {
                              uploadDocuments(e.target.files[0], "idProof");
                            }}
                          />
                          {idProof && <div>{idProofName}</div>}
                          {idProof && !idntf && (
                            <div
                              style={{ color: "blue", cursor: "pointer" }}
                              className="testimonial-record"
                              onClick={(e) => {
                                e.stopPropagation();
                                downloadFile(`${idProof}`, idProof);
                              }}
                            >
                              Identity Proof Uploaded
                              <a id={`${idProof}`}></a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="cjr_control_buttons">
                    {!(navCurr == nav[0]) && (
                      <div
                        className="cjr_back"
                        onClick={() => backward("upload")}
                      >
                        Back
                      </div>
                    )}
                    <div className="cjr_next" onClick={() => forward("upload")}>
                      Next
                    </div>
                  </div>
                </div>
              )}

              {navCurr == "certi" && (
                <div className="cjr_certificate_final">
                  <div className="cjr_body_head">
                    Congratulations! Your certificate is Ready
                  </div>
                  <div
                    className="cjr_body_description"
                    style={{ marginTop: "16px" }}
                  >
                    You can download the certificate of
                    <span style={{ color: "black", fontWeight: "600" }}>
                      {" "}
                      {`${courseName} `}
                    </span>
                    anytime and share this achievement on LinkedIn to attract
                    more job opportunities. We're happy for you.
                  </div>

                  <img
                    src="https://cdn.pegasus.imarticus.org/certificates/certi_jr.svg"
                    alt="certi_jr"
                    style={{ marginTop: "48px" }}
                  />
                  <div
                    className="cjr_download"
                    onClick={applyCerti}
                    style={{ marginTop: "48px" }}
                  >
                    Download Certificate
                    <img
                      src="https://cdn.pegasus.imarticus.org/certificates/Download.svg"
                      alt="Download"
                    />
                  </div>
                  <div
                    className="cjr_download_back"
                    onClick={() => {
                      window.location = document.referrer;
                    }}
                  >
                    Back To Main Menu
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.brand?.brand || state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  primaryEmail: state.profile.email,
  ppic: state.profile.ppic,
  email: state.primaryEmail.secondary_emails[0],
});

const mapDispatchToProps = {
  getProfileInfoOnly,
  dispatchIndividualBrand,
  setSelectedCourse,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Certificate)
);
